@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

/* Car Modal */
.modal-container {
    overflow-y: auto;
    max-height: 80vh;
    border-radius: 4px;
}

.modal-close {
    position: absolute !important;
    top: 10px;
    right: 10px;
}

.car-modal {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.car-modal > div:first-child {
    flex: 2;
}

.car-modal > div:last-child {
    flex: 1;
    border-radius: 6px;
    overflow: hidden;
    width: fit-content;
    height: fit-content;
}

.infos-chip-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
}

.infos-chip {
    background-color: var(--light);
    padding: 5px 10px;
    border-radius: 8px;
    color: var(--blue);
}

.infos-car-container {
    margin-top: 10px;
    position: relative;
}

.link-btn {
    position: absolute !important;
    top: 0px;
    right: 0px;
    background-color: var(--blue) !important;
    color: var(--light) !important;
    box-shadow: none !important;
}

/* Car Modal v2 */

.modal-2-container {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 85vh;
    border-radius: 8px;
    padding: 15px 25px 15px 25px !important;
    font-family: "Inter", sans-serif !important;
}

.modal-2-container > div {
    display: flex;
    flex-direction: row;
}

.modal-2-container h6 {
    font-size: 16px;
    font-weight: 700;
}

.modal-2-container h5 {
    font-size: 22px;
    font-weight: 700;
}

.modal-2-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.modal-2-header > div:last-child {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.modal-2-header > div:last-child > * {
    cursor: pointer;
}

.modal-2-header > div:last-child > div {
    background-color: var(--black);
    color: var(--light);
    padding: 5px 15px;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5px;
}

.modal-2-header > div:last-child > div > * {
    margin: auto;
}

.modal-2-left {
    flex: 3;
    padding-right: 15px;
    border-right: solid 1px var(--grey);
}

.modal-2-left > div {
    margin-bottom: 10px;
    margin-top: 10px;
}

.modal-2-left-price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.modal-2-left-price > div:nth-child(2) {
    text-align: center;
    align-content: center;
    padding-left: 7px;
    padding-right: 7px;
    border: solid 1px var(--black);
}

.modal-2-right {
    flex: 2;
    padding-left: 15px;
    position: relative;
}

.modal-2-right > div {
    margin-bottom: 15px;
    margin-top: 15px;
}


.modal-2-right > div:last-child {
    width: 80%;
    background-color: #aaaaaa;
    position: relative;
}

.modal-2-feature > div:nth-child(1) {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.modal-2-feature > div:nth-child(2) {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.modal-2-feature > div:nth-child(3) {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    justify-content: space-around;
}

.modal-2-feature-title {
    margin-top: 20px;
}

.modal-2-feature-item {
    display: flex;
    flex-direction: row;
    flex: 1;
}

.modal-2-feature-item > div:first-child {
    align-items: center;
    margin: auto;
}

.modal-2-feature-item > div:last-child {
    flex: 2;
    margin-left: 10px;
}

.modal-2-feature-item-title {
    font-weight: 400;
    font-size: 11px;
}

.modal-2-feature-item-texte {
    font-weight: 600;
    font-size: 13px;
}

.modal-2-pricing > div:nth-child(1) {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.modal-2-pricing-bar {
    position: relative;
}

.modal-2-pricing-bar > div:nth-child(1) {
    height: 20px;
    width: 93%;
    margin: auto;
    position: relative;
}

.modal-2-pricing-bar > div:nth-child(1) > span {
    position: absolute;
    font-weight: 700;
}

.modal-2-pricing-bar > div:nth-child(2) {
    position: relative;
    padding-top: 2px;
    padding-bottom: 6px;
    width: 93%;
    margin: auto;
}

.modal-2-pricing-bar-color {
    /* margin-top: 20px; */
    border-radius: 999px;
    width: 100%;
    height: 6px;
    background: rgb(0,16,165);
    background: linear-gradient(90deg, rgba(0,16,165,1) 0%, rgba(255,0,0,1) 100%);
}

.modal-2-pricing-bar-round {
    position: absolute;
    top: 0px;
    width: 7px;
    height: 7px;
    border-radius: 999px;
    border: solid 2px white;
    background-color: #c3c3c3;
    box-shadow: 0px 2px 4px 0px var(--black);
}

.modal-2-pricing-bar-lower {
    position: absolute;
    top: 0px;
    left: 3%;
    width: 2px;
    height: 12px;
    border-radius: 999px;
    border: solid 1.5px white;
    background-color: #ffffff94;
    box-shadow: 0px 2px 4px 0px var(--black);
}

.modal-2-pricing-bar-higher {
    position: absolute;
    top: 0px;
    right: 3%;
    width: 2px;
    height: 12px;
    border-radius: 999px;
    border: solid 1.5px white;
    background-color: #ffffff94;
    box-shadow: 0px 2px 4px 0px var(--black);
}

.modal-2-pricing-bar-reference {
    position: absolute;
    top: 0px;
    width: 2.5px;
    height: 15px;
    border-radius: 999px;
    /* border: solid 2px white; */
    background-color: var(--black);
    box-shadow: 0px 2px 4px 0px var(--black);
}

.modal-2-pricing-bar > div:nth-child(3){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 93%;
    margin: auto;
    position: relative;
}

.modal-2-pricing-bar > div:nth-child(3) > div > span{
    font-size: 11px;
    color: #aaaaaa;
}

.modal-2-pricing > div:nth-child(4) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 70%;
    margin: 5px auto auto 5%;
}

.modal-2-pricing > div:nth-child(4) > div {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-content: center;
}


.modal-2-pricing > div:nth-child(4) > div > span{
    color: #aaaaaa;
}

.modal-2-pricing-bar-reference-legend {
    margin: auto 0px auto 0px;
    height: 15px;
    width: 2.5px;
    border-radius: 999px;
    background-color: var(--black);
    box-shadow: 0px 2px 4px 0px var(--black);
}

.modal-2-pricing-graph-other-legend {
    margin: auto 0px auto 0px;
    height: 8px;
    width: 8px;
    border-radius: 999px;
    background-color: var(--black);
    box-shadow: 0px 2px 4px 0px var(--black);
}

.modal-2-pricing-graph-selected-legend {
    margin: auto 0px auto 0px;
    height: 8px;
    width: 8px;
    border-radius: 999px;
    background-color: #aaaaaa;
    box-shadow: 0px 2px 4px 0px var(--black);
}

.modal-2-pricing > div:nth-child(3) {
    width: 100%;
    height: 30vh;
    overflow: hidden;
    position: relative;
    display: flex;
}

.modal-2-image {
    height: 200px;
    width: 100%;
    border-radius: 4px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.modal-2-urls {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 15vh;
    overflow: auto;
    margin-right: 10px;
}

.modal-2-urls::-webkit-scrollbar {
    width: 8px;
}

.modal-2-urls::-webkit-scrollbar-track {
    background: none; 
}

.modal-2-urls::-webkit-scrollbar-thumb {
    background: var(--black);
    border-radius: 10px; 
}

.modal-2-urls::-webkit-scrollbar-thumb:hover {
    background: var(--black); 
}

.modal-2-url-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
    margin-right: 10px;
    background-color: var(--black);
    color: var(--light);
    padding: 2px 8px;
    border: solid 2px var(--black);
}

.modal-2-url-item:hover {
    background-color: white;
    color: var(--black);
    border: solid 2px var(--black);
}

.modal-2-url-item span {
    margin: auto auto auto 0;
}

.modal-2-container::-webkit-scrollbar {
    width: 8px;
}

.modal-2-container::-webkit-scrollbar-track {
    background: none; 
}

.modal-2-container::-webkit-scrollbar-thumb {
    background: var(--black);
    border-radius: 10px; 
}

.modal-2-container::-webkit-scrollbar-thumb:hover {
    background: var(--black); 
}

@media (max-width: 1250px) {
    .modal-2-container {
        width: 90% !important;
        max-height: 90vh;
    }

    .modal-2-container > div:last-child {
        flex-direction: column-reverse;
    }

    .modal-2-left {
        border-right: none;
    }

    .modal-2-right {
        padding-left: 0px;
    }

    .modal-2-urls {
        margin-right: 0px;
        max-height: none;
    }
    
}



@media (max-width: 750px) {
    .modal-2-container {
        width: 100% !important;
        max-height: 100vh;
    }

    .modal-2-container > div {
        padding: 10px;
    }
}
