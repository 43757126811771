@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

@font-face {
  font-family: 'Tesla Logo';
  src: url('../../assets/fonts/tesla/TESLA.ttf');
}

/* https://coolors.co/06387e-aa002b-e8eef2-afafaf-e0ca3c-048a81 */
:root {
    --primary-color: #387bbe;
    --secondary-color: #E26D5C;
    --blue: #06387e; /* #03224C; */
    --red: #aa002b; /* #5D0001; */
    --light: #E8EEF2;
    --yellow: #E0CA3C;
    --cyan: #048A81;
    --grey: #F0F6F5;
    --white: #F1F1F1;
    --black: #1f1f1f;
}

:root {
    --primary-color: #3D5A6C;
    --secondary-color: #FDCA40;
    --blue: #3D5A6C; /* #03224C; */
    --red: #470024; /* #5D0001; */
    --light: #E8EEF2;
    --yellow: #FDCA40;
    --cyan: #048A81;
    --grey: #F4F4F4;
    --white: #F1F1F1;
    --black: #1f1f1f;
    --dark: #010101;
}

.bg-blue {
    background-color: var(--blue) !important;
}

.bg-red {
    background-color: var(--red) !important;
}

.bg-yellow {
    background-color: var(--yellow) !important;
}

.bg-cyan {
    background-color: var(--cyan) !important;
}

.bg-white {
    background-color: var(--white) !important;
}

.bg-grey {
    background-color: var(--grey) !important;
}

.tesla-font {
    font-family: 'Tesla Logo';
}

.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 8vh;
    background-color: var(--blue);
    color: var(--light);
}

.navbar > div {
    margin: auto;
}

.navbar > div:nth-child(2) {
    text-align: center;
}

.filter-btn {
    color: var(--light) !important;
}

.navbar-btns {
    display: flex;
    flex-direction: row;
    justify-content:flex-end;
    gap: 10px;
    margin-right: 20px;
}

.navbar-btns button {
    color: var(--light) !important;
}

.content-panel {
    min-height: 92vh;
    overflow-y: auto;
}

.infos-panel {
    margin: auto;
    width: 90%;
    padding: 10px;
    margin-top: 20px;
    border-radius: 8px;
    background-color: var(--light);
}

.infos-panel h6 {
    margin-bottom: 0px;
}

.infos-recap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

.infos-recap > div {
    width: 24%;
    text-align: center;
    background: rgba(255,255,255,0.7);
    backdrop-filter: blur(5px);
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    padding: 0px;
}

.infos-recap > div > div {
    flex: 1;
    height: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.infos-recap > div > div:first-child {
    background-color: var(--blue);
    border-radius: 8px 0px 0px 8px;
    color: var(--light);
}


/* Car Card */

.car-card {
    text-decoration: none;
    color: var(--black) !important;
}

.card-seller {
    position: absolute;
    top: 0;
    right: 10px;
    background: var(--light);
    color: var(--blue);
    padding: 5px 10px;
    border-radius: 0 0 6px 6px;
    font-size: 1.2vw;
    box-shadow: -2px 2px 5px rgba(255, 255, 255, 0.414);
}

.card-year {
    position: absolute;
    top: 0;
    right: 0;
    background: #e8eef2c7;
    color: var(--blue);
    padding: 5px 10px;
    border-radius: 0 0px 0 8px;
    font-size: 1.2vw;
    box-shadow: -2px 2px 5px rgba(255, 255, 255, 0.414);
}

.chip {
    color: var(--white);
    font-size: 10px;
    padding: 3px 6px;
    border-radius: 8px;
}

.box-card {
    padding-top: 10px;
}

.km-bar {
    background-color: #dddddd98 !important;
    height: 10px !important;
    border-radius: 5px !important;
}

.km-bar > span {
    background-color: var(--blue) !important;
}

.price-bar {
    background-color: #dddddd98 !important;
    height: 10px !important;
    border-radius: 5px !important;
}

.price-bar > span {
    background-color: var(--cyan) !important;
}

.panel-analysis {
    margin-top: 25px;
    background-color: var(--light);
    padding: 4px 10px;
    border-radius: 4px;
}

.card-analysis {
    padding: 5px 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.card-analysis > div:first-child > * {
    /* margin-bottom: 0px; */
    width: 20px;
    color: var(--white);
    padding: 2px 5px;
    border-radius: 4px;
    text-align: center;
}

.progress {
    background-color: var(--grey);
    height: 8px;
    border-radius: 8px;
}

.progress > div {
    height: 8px;
    border-radius: 8px;
}

.card-footer {
    display: flex;
    flex-direction: row;
    padding: 16px 0px 0px 0px !important;
    height: 6vh !important;
}

.card-footer > div {
    flex: 1;
    width: 100%;
    overflow: hidden;
    background-color: var(--light);
    color: var(--black);
    font-size: 0.75vw;
    min-height: 5vh;
}

.card-footer > div {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-footer > div:first-child {
    border-radius: 0 0 0 6px;
}

.card-footer > div:last-child {
    border-radius: 0 0 6px 0;
}


/* Filter Drawer */

.filter-container {
    padding: 10px 20px 0px 20px;
}

.filter-close {
    display: flex;
    flex-direction: row-reverse;
    cursor: pointer;
}

.filter-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
}

.filter-header h6 {
    font-weight: 600;
}

.filter-reset {
    display: flex;
    flex-direction: row-reverse;
}

.filter-reset > div {
    cursor: pointer;
    padding: 10px 15px;
    border-radius: 3px;
    font-weight: 600;
    height: fit-content;

    color: rgb(202, 0, 0);
}

.filter-reset > div:hover {
    background-color: var(--light);
    color: rgb(202, 0, 0);
}

.filter-period {
    margin-top: 20px;
}

.filter-period > div  {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.filter-period > div > button {
    border: solid 1px var(--black) !important;
    border-radius: 2px !important;
    margin: auto 4px;
    padding: 0px;
    flex: 1;
    color: var(--black);
}

.filter-period > div > .Mui-selected {
    background-color: var(--black) !important;
    color: var(--light) !important;
}

.filter-price {
    margin-top: 20px;
}

.filter-price > div {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.filter-price > div > div:first-child {
    margin-left: 0px;
}

.filter-price > div > div:last-child {
    margin-right: 0px;
}

.filter-price > div > div > div {
    border-radius: 2px !important;
}

.filter-price > div > div > div > input {
    /*padding: 10px 0px 10px 7px;*/
}

.filter-year {
    margin-top: 20px;
}

.filter-year > div {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.filter-year > div > div {
    flex: 1;
}

.filter-seller {
    margin-top: 20px;
}

.filter-seller > div {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.filter-seller > div > div {
    flex: 1;
}

/* Mention */

.mention-container {
    width: 90%;
    margin: auto;
}

/* Footer */
.footer {
    width: 100%;
    height: 8vh;
    background-color: var(--light);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer-container {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer-container a {
    text-decoration: none;
    color: var(--blue);
}

@media (max-width: 800px) {
    .navbar {
        flex-direction: column-reverse;
        height: 15vh;
    }
    .navbar-btns {
        justify-content: space-around;
        margin: auto;
    }
    .content-panel {
        min-height: 85vh;
    }
    .infos-recap {
        flex-direction: column;
    }
    .infos-recap > div{
        margin: auto;
        margin-top: 10px;
        width: 90%;
    }
    .modal-container {
        width: 95%;
    }
    .car-modal {
        flex-direction: column-reverse;
    }
    .car-modal > div:first-child {
        width: 100%;
    }
    .car-modal > div:last-child {
        width: 100%;
    }
}