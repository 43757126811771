@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sen:wght@400..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.cars-container {
    /*font-family: "Montserrat", sans-serif !important;*/
    font-family: "Inter", sans-serif !important;
}

.cars-container h4, 
.cars-container h5, 
.cars-container h6 {
    font-family: "Inter", sans-serif !important;
}

.cars-container p, 
.cars-container span {
    font-family: "Inter", sans-serif !important;
}

.cars-container .home-navbar > div > a {
    color: var(--light);
}

.cars-container .home-navbar > div > a:hover {
    border-bottom: 1px solid var(--light);
}

.cars-img {
    height: 30vh;
    position: relative;
}

.cars-img > div:first-child {
    background-image: url("../assets/images/tesla-gallery-model-3-2024/Model3_54.jpg");
    background-size: cover;
    background-position: center;
    filter:brightness(0.6);
    height: 100%;
    width: 100%;
}

.cars-welcome {
    position: absolute;
    bottom: 0px;
    color: var(--light) !important;
    /* height: 20vh; */
    width: 100%;
    padding-bottom: 20px;
}

.cars-welcome > div {
    width: 50%;
    margin: auto;
    display: flex;
    flex-direction: row-reverse;
}

.cars-welcome h4 {
    font-family: "Sen", sans-serif !important;
    font-weight: 500;
}

.cars-welcome span {
    font-family: "Sen", sans-serif !important;
}

.cars-welcome > div > div:first-child {
    flex: 3;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.cars-welcome > div > div:first-child > div {
    flex: 1;
}

/*.cars-welcome > div > div:last-child {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.cars-welcome > div > div:last-child > div {
    margin: auto;
    cursor: pointer;

    color: var(--light);
    font-weight: 600;
    border: 3px solid var(--light);

    color: var(--dark);
    background-color: var(--light);
    border-radius: 3px;
    padding: 7px 30px;

    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.cars-welcome > div > div:last-child > div:hover {
    background-color: var(--light);
    color: var(--dark);

    background-color: var(--grey);
    border: 3px solid var(--grey);
}*/

.cars-panel {
    display: flex;
    flex-direction: row;
}

.cars-filter {
    position: relative;
    flex: 1;
}

.cars-cards-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 15px;
}

.cars-cards-header > div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
}

.cars-cards-header > div:last-child {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.cars-cards-header > div:last-child > div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
}

.cars-cards-header > div:last-child > div:last-child > div > div {
    padding: 5px 30px 5px 10px;
}

.cars-cards-header h6 {
    font-weight: 300;
    color: grey
}

.cars-cards-header h6 strong {
    font-weight: 500;
    color: var(--dark);
}

.cars-filter-btn {
    background-color: var(--dark) !important;
    color: var(--light) !important;
    padding: 5px 20px !important;
    font-weight: 400 !important;
}

.cars-cards {
    position: relative;
    flex: 3;
    background-color: var(--grey);
}


.cars-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 50px;
    position: relative;
}

.cars-cards-container > div {
    width: 32%;
    margin-bottom: 15px;
}

/* Car Card */
.car-card {
    border-radius: 4px;
}

.car-card > button > div {
    margin: 15px;
}

.car-card-image {
    border-radius: 2px;
    overflow: hidden;
    position: relative;
}

.car-card-class {
    position: absolute;
    z-index: 1;
    bottom: 10px;
    left: 10px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    border-radius: 4px;
    padding: 2px 7px;
}

.car-card-class > div {
    padding: 1px 2px 1px 1px;
    margin: 2px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
}

.car-card-header {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.car-card-header > div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.car-card-header > div:first-child > h6 {
    font-weight: 600;
    font-size: 18px;
}

.car-card-header > div:last-child > h6 {
    font-weight: 1000;
    letter-spacing: 1px; 
    font-size: 24px;
}

.car-card-cat {
    margin-bottom: 10px;
}

.car-card-cat h6 {
    font-weight: 500;
    font-size: 14px;
    color: #5C5E62;
}

.car-card-desc {
    margin-top: 10px;
}

.car-card-desc > div {
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    gap: 7px;
    text-align: center;
    align-items: center;
}

.car-card-desc > div p {
    font-size: 12px;
    font-weight: 700;
}

/* Ad Card */
.car-card-ad-container {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    overflow: hidden;
}



/* 1250px */
@media (max-width: 1250px) {
    .cars-welcome > div {
        flex-direction: column;
        width: 100%;
    }

    .cars-welcome > div > div:first-child {
        flex: 1;
        margin-bottom: 30px;
    }

    .cars-welcome > div > div:last-child {
        flex: 1;
    }

    .cars-welcome > div > div:last-child > div {
        width: 75%;
        margin: auto;
    }

    .cars-filter {
        display: none;
    }

    .cars-cards-header {
        flex-direction: column;
    }

    .cars-cards-header > div:first-child {
        text-align: left;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .cars-cards-header > div:first-child > h6 {
        margin: auto 0px;
    }

    .cars-cards-header > div:last-child {
        align-self: flex-end;
    }

    .cars-cards-container {
        flex-direction: column;
        gap: 20px;
    }
    .cars-cards-container > div {
        width: 90%;
        margin: auto;
    }
}
