@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sen:wght@400..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.home-container {
    /*font-family: "Montserrat", sans-serif !important;*/
    font-family: "Inter", sans-serif !important;
}

.home-container p {
    font-size: 23px !important;
    color: var(--black) !important;
    /*font-family: "Montserrat", sans-serif !important;*/
    font-family: "Inter", sans-serif !important;
}

.home-container h4 {
    font-size: 35px !important;
    color: var(--black) !important;
    font-family: "Montserrat", sans-serif !important;
}

.home-container {

}

.home-navbar {
    position: absolute;
    width: 100%;
    height: 8vh;
    z-index: 1;
    padding-top: 10px;
}

.home-navbar > div {
    width: fit-content;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    position: relative;
}

.home-navbar > div > a {
    padding: 7px 20px;
    margin: 10px; 
    border: none;
    position: relative;
}

.home-navbar > div > a:hover {
    /* border-bottom: 2px solid var(--black); */
    cursor: pointer;
    border-bottom: 1px solid var(--black);
}

.home-navbar a {
    text-decoration: none;
    color: var(--black);
}

.home-img {
    position: relative;
}

.home-img > img {
    width: 100%;
}

.home-img > div {
    position: absolute;
    top: 20%;
    left: 15%;
    text-align:left; 
}

.home-img > div > h3 {
    font-size:34px; 
    font-weight:500; 
    text-transform:uppercase;
}
.home-img > div > h4 {
    font-size:28px; 
    font-weight:300; 
    text-transform:uppercase;
}

.home-img > div:before {
    background-color: #c50000;
    border-radius: 0.25rem;
    content: '';
    display: block;
    height: 0.25rem;
    width: 42px;
    margin-bottom: 1.25rem;
}

.home-img > div > h3 {
    font-size: 55px;
    line-height:1.5em; 
    padding-bottom:10px; 
    text-transform:uppercase;
    letter-spacing: 2px; 
    color:var(--black);
    

    font-family: "Sen", sans-serif !important;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
}

.home-img > h3 > p {
    font-size: 40px;
    font-weight: 300;
    margin: 0px;
}

.home-img {
    background-color: var(--dark);
}

.home-img > div > a {
    text-decoration: none;
    border-radius: 3px;
    border: 3px solid var(--black);
    color: var(--black);
    padding: 10px 30px;
    font-weight: 600;
}

.home-img > div > a:hover {
    background-color: var(--black);
    color: var(--light);
}

.home-welcome {
    background-color: var(--dark);
}

.home-welcome > div {
    width: 80%;
    margin: auto;
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: row-reverse;
}

.home-welcome > div div:last-child {
    flex: 1;
    position: relative;
}

.home-welcome > div div:first-child > p {
    color: var(--light) !important;
}

.home-welcome > div div:last-child > img {
    width: 80%;
    margin: auto;
    border-radius: 3px;
}

.home-welcome > div div:first-child {
    flex: 1;
    text-align: left;
    margin: auto;
}

.home-welcome > div div:first-child > p {
    /* width: 80%;
    margin: auto; */
}

.lineaire-simple {
    background: linear-gradient(var(--dark), var(--light));
    height: 10vh;
    position: absolute;
}

.home-explore {
    background-color: var(--light);
}

.home-explore > div {
    width: 80%;
    margin: auto;
    padding: 100px 0px;
}

.home-explore > div > a {
    text-decoration: none;
    border-radius: 3px;
    border: 3px solid var(--black);
    color: var(--black);
    padding: 10px 30px;
    font-weight: 600;
}

.home-explore > div > a:hover {
    background-color: var(--black);
    color: var(--light);
}

.home-explore h4 {
    font-size: 40px !important;
    margin-bottom: 30px;
}

.home-explore p {
    font-size: 20px;
    margin-bottom: 30px;
}

.home-techno {
    display: flex;
    flex-direction: row;
    width: 80%;
    margin: auto;
    margin-top: 150px;
}

.home-techno > div:last-child {
    flex: 1;
    text-align: center;
}

.home-techno > div:last-child> img {
    width: 80%;
    margin: auto;
    border-radius: 10px;
}

.home-techno > div:first-child{
    flex: 1;
    text-align: left;
    margin: auto;
}

.home-techno > div > h4 {
    margin-bottom: 30px;
}

.home-join {
    margin-top: 150px;
    background-color: var(--dark);
    padding: 60px 0px;
}

.home-join > div {
    width: 80%;
    position: relative;
    margin: auto;
}

.home-join > div > h4 {
    margin-bottom: 100px;
    color: var(--light) !important;
}

.home-join > div > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    width: 100%;
    padding-bottom: 40px;
}

.home-join > div > div > div {
    width: 28%;
    text-align: left;
    border-top: 5px solid var(--light);
    backdrop-filter: blur(5px);
    color: var(--light) !important;
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-left: 0px;
    font-size: 20px;
}

.home-join > div > div > div > strong {
    font-size: 30px;
}

.home-join > div > p {
    margin-top: 30px;
    font-size: 17px !important;
    color: var(--light) !important;
}

.home-bannere {
    background-image: url('../assets/images/tesla-gallery-model-3-2024/Model3-70.jpg');
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    height: 120vh;
    margin-top: -25vh;
}

.home-end {
    /* margin-top: 100px; */
    background-color: var(--light);
    padding: 50px 0px;
}

.home-end > div {
    width: 80%;
    margin: auto;
}

/* 1250px */
@media (max-width: 1250px) {
    .home-img > div > h3 {
        color: var(--light);
    }
    .home-img > div > h4 {
        color: var(--light) !important;
    }
    .home-img {
        height: auto;
    }
    .home-img > div {
        width: 80%;
        margin: auto;
        position: relative;
        top: auto;
        left: auto;
        margin-top: 30px;
    }
    .home-img > div > h3 {
        font-size: 7vw !important;
    }
    .home-img > div > h4 {
        font-size: 5vw !important;
    }
    .home-img > div > a {
        color: var(--light);
        border: 3px solid var(--light);
    }
    .home-img > div > a:hover {
        background-color: var(--light);
        color: var(--black);
    }
    .home-welcome {
        display: block;
    }
    .home-welcome div:first-child {
        padding-bottom: 50px;
    }
    .home-techno {
        display: block;
    }
    .home-techno > div:last-child {
        margin-top: 50px;
    } 
    .home-join > div > div {
        display: block;
    }
    .home-join > div > div > div {
        width: 100%;
        margin-bottom: 50px;
    }
}